import React, { Suspense, useEffect, useState, lazy } from "react";

// Libraries
import { Routes, Route, useLocation } from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";

// Context
import GlobalContext from "./Context/Context";

// Components
import ScrollToTopButton from "./Components/ScrollToTop";

// Home
const AbailabilityPage = lazy(() => import("./Pages/Home/Abailability"));
const PeteBusinessPage = lazy(() => import("./Pages/Home/Pete"));
const LocationPage = lazy(() =>
  import("./Pages/Portfolios/PortfolioBoxed/LocationPage")
);
const PortfolioBoxedMetroPage = lazy(() =>
  import("./Pages/Portfolios/PortfolioBoxed/PortfolioBoxedMetro")
);

// About Pages
const AboutUsPage = lazy(() => import("./Pages/About/AboutUsPage"));
const OurStoryPage = lazy(() => import("./Pages/About/OurStoryPage"));
const ContactUsClassicPage = lazy(() =>
  import("./Pages/Contact/ContactUsClassicPage")
);
const NotFoundPage = lazy(() => import("./Pages/404"))

const FaqSPage = lazy(() => import("./Pages/AdditionalPages/FaqSPage"));

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false,
  });
  const location = useLocation();

  // RetinaJS
  useEffect(() => {
    window.addEventListener("load", retina(document.querySelectorAll("img")));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      import("./Functions/Utilities").then((module) => {
        module.SetHeaderMenuPos();
        module.setDocumentFullHeight();
      });
    }, 1000);
  }, [location]);

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
        {
          <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
            <ScrollToTopButton />
            <AnimatePresence exitBeforeEnter>
              <Suspense fallback={<></>}>
                <Routes>

                  <Route
                    path="/"
                    element={
                      <PeteBusinessPage style={{ "--base-color": "#3452ff" }} />
                    }
                  />

                  <Route
                    path="page/product"
                    element={
                      <PortfolioBoxedMetroPage
                        style={{ "--base-color": "#fff" }}
                      />
                    }
                  />

                  <Route
                    path="/page/abailability"
                    element={
                      <AbailabilityPage style={{ "--base-color": "#3452ff" }} />
                    }
                  />

                  <Route
                    path="/page/faq-s"
                    element={<FaqSPage style={{ "--base-color": "#0038e3" }} />}
                  />

                  <Route
                    path="/page/contact"
                    element={
                      <ContactUsClassicPage
                        style={{ "--base-color": "#0038e3" }}
                      />
                    }
                  />

                  <Route
                    path="/page/about-us"
                    element={
                      <AboutUsPage style={{ "--base-color": "#0038e3" }} />
                    }
                  />

                  <Route
                    path="/page/our-story"
                    element={
                      <OurStoryPage style={{ "--base-color": "#0038e3" }} />
                    }
                  />

                  <Route
                    path="/page/location"
                    element={
                      <LocationPage style={{ "--base-color": "#fff" }} />
                    }
                  />

                  <Route
                    path="/page/error-404"
                    element={
                      <NotFoundPage style={{ "--base-color": "#0038e3" }} />
                    }
                  />

                  <Route path="*" element={<NotFoundPage />} />

                </Routes>
              </Suspense>
            </AnimatePresence>
          </main>
        }
      </div>
    </GlobalContext.Provider>
  );
}

export default App;
